import createPersistedState from "vuex-persistedstate";
import { createStore } from 'vuex'

export default createStore({
    state: {
        deviceType: '',
        envMode: '',
        axiosHeaders: {},
        isLogin: false,
        sideBarShow: true,
        userInfo: {},
        videoWide: false,
        preRoute: '',
        alertStr: '',
        order: {},
        createOrderShow: false,
        createFresh: false,
        searchResultShow: false,
        searchResult: {},
        edited: {}
    },
    getters: {
        getDeviceType: state => {
            return state.deviceType;
        },
        getEnvMode: state => {
            return state.envMode;
        },
    },
    mutations: {
        setDeviceType(state, payload) {
            state.deviceType = payload
        },
        setEnvMode(state, payload) {
            (payload !== 'devel' && payload !== 'prod') ?
                state.envMode = 'devel' :
                state.envMode = payload
        },
        setAxiosHeaders(state, payload) {
            if (payload.val) {
                state.axiosHeaders[payload.key] = payload.val;
            } else {
                delete state.axiosHeaders[payload.key];
            }
        },
        setIsLogin(state, payload) {
            state.isLogin = payload
        },
        setSideBarShow(state, payload) {
            state.sideBarShow = payload
        },
        setUserInfo(state, payload) {
            state.userInfo = payload
        },
        setVideoWide(state, payload) {
            state.videoWide = payload
        },
        setRegisterWindowShow(state, payload) {
            state.registerWindowShow = payload
        },
        setForgetPasswordWindowShow(state, payload) {
            state.forgetPasswordWindowShow = payload
        },
        setPreRoute(state, payload) {
            state.preRoute = payload
        },
        setAlertStr(state, payload) {
            state.alertStr = payload
        },
        setOrder(state, payload) {
            state.order = payload
        },
        setCreateOrderShow(state, payload) {
            state.createOrderShow = payload
        },
        setCreateFresh(state, payload) {
            state.createFresh = payload
        },
        setSearchResultShow(state, payload) {
            state.searchResultShow = payload
        },
        setSearchResult(state, payload) {
            state.searchResult = payload
        },
        setEdited(state, payload) {
            state.edited = payload
        },

    },
    actions: {},
    modules: {},
    plugins: [createPersistedState({
        paths: [
        ],
    })],
})