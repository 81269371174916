import { createApp } from "vue";
import router from "./router";
import store from "./store";
import App from "./App.vue";

import "bootstrap";
import "bootstrap/dist/js/bootstrap.min.js";
import "@/assets/custom.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/fontawesome-free/js/regular.min.js";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

const app = createApp(App);
app.config.globalProperties.appName = "logistics";
app.config.globalProperties.appEnv = "prod"; // prod or devel
app.config.globalProperties.apiHost = process.env.VUE_APP_API_HOST

app.use(router).use(store).use(VueDatePicker).mount("#app");
