import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: HomeView
    },
    {
        path: '/page/:arrs*',
        name: 'PageContentView',
        component: () => import(/* webpackChunkName: "PageContentView" */ '@/views/PageContentView.vue'),
        props: route => {
            const params = route.params
            const props = {}
            if (params.length > 0) {
                for (let i = 0; i < params.length; i++) {
                    props[params[i]] = i + 1 < params.length ? params[i + 1] : null
                }
            }
            return props
        }
    },
    {
        path: '/news/:arrs*',
        name: 'NewsContentView',
        component: () => import(/* webpackChunkName: "NewsContentView" */ '@/views/NewsContentView.vue'),
        props: route => {
            const params = route.params
            const props = {}
            if (params.length > 0) {
                for (let i = 0; i < params.length; i++) {
                    props[params[i]] = i + 1 < params.length ? params[i + 1] : null
                }
            }
            return props
        }
    },
    {
        path: '/new_item/:arrs*',
        name: 'NewsContenItemtView',
        component: () => import(/* webpackChunkName: "NewsContenItemtView" */ '@/views/NewsContentItemView.vue'),
        props: route => {
            const params = route.params
            const props = {}
            if (params.length > 0) {
                for (let i = 0; i < params.length; i++) {
                    props[params[i]] = i + 1 < params.length ? params[i + 1] : null
                }
            }
            return props
        }
    },
    {
        path: '/login',
        name: 'LoginView',
        component: () => import(/* webpackChunkName: "LoginView" */ '@/views/LoginView.vue'),
    },
    {
        path: '/search',
        name: 'SearchView',
        component: () => import(/* webpackChunkName: "SearchView" */ '@/views/SearchView.vue'),
    },
    {
        path: '/print',
        name: 'PrintView',
        component: () => import(/* webpackChunkName: "SearchView" */ '@/views/PrintView.vue'),
    },
    {
        path: '/ready',
        name: 'ReadyView',
        component: () => import(/* webpackChunkName: "SearchView" */ '@/views/ReadyView.vue'),
    },
    {
        path: '/history',
        name: 'HistoryView',
        component: () => import(/* webpackChunkName: "SearchView" */ '@/views/HistoryView.vue'),
    },
    {
        path: '/create',
        name: 'CreateView',
        component: () => import(/* webpackChunkName: "SearchView" */ '@/views/CreateView.vue'),
    },
    {
        path: '/quick_app',
        name: 'QuickAppView',
        component: () => import(/* webpackChunkName: "QuickAppView" */ '@/views/QuickAppView.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/',
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
