<template>
  <vue-loading></vue-loading>
  <vue-env></vue-env>
  <div class="container-fluid">
    <NavBar></NavBar>
  </div>

  <!-- <div class="container">
    <div class="row">
      <div class="col-12"> -->
  <router-view />
  <!-- </div>
    </div>
  </div> -->

  <FooterContent></FooterContent>
</template>

<script>
import VueEnv from "@/components/VueEnv.vue";
import VueLoading from "@/components/VueLoading.vue";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { useCookies } from "vue3-cookies";
import { defineAsyncComponent } from "vue";

export default {
  name: "App",
  components: {
    VueEnv,
    VueLoading,
    NavBar: defineAsyncComponent(() => import("@/components/NavBar.vue")),
    FooterContent: defineAsyncComponent(() =>
      import("@/components/FooterContent.vue")
    ),
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      deviceType: "pc",
    };
  },
  created() {
    this.mode = this.$store.state.envMode;
    this.initUuid();
    this.initAxiosHeader();
    this.initToken();
    this.$watch(
      () => this.$store.state.axiosHeaders,
      () => {
        this.axiosHeaders = this.$store.state.axiosHeaders;
      }
    );
    this.$watch(
      () => this.$store.state.userInfo,
      () => {
        if (this.$store.state.userInfo) {
          this.userInfo = this.$store.state.userInfo;
          this.$store.commit('setIsLogin', true)
          if (this.$route.path == '/login')
            this.$router.push({ path: "/create" });
        }
      }
    );
  },
  mounted() { },
  methods: {
    initUuid() {
      let uuidItem =
        document.cookie
          .match("(^|;)\\s*" + this.appName + "-uuid\\s*=\\s*([^;]+)")
          ?.pop() || "";
      if (uuidItem === "") {
        uuidItem = uuidv4();
        this.cookies.set(this.appName + "-uuid", encodeURI(uuidItem));
      }
      this.uuid = uuidItem;
    },
    initAxiosHeader() {
      this.$store.commit("setAxiosHeaders", {
        key: "uuid",
        val: this.uuid,
      });
    },
    initToken() {
      let tokenItem =
        document.cookie
          .match("(^|;)\\s*" + this.appName + "-token\\s*=\\s*([^;]+)")
          ?.pop() || "";
      if (tokenItem !== "") {
        this.token = decodeURI(tokenItem);
        this.checkToken();
      }
    },
    checkToken() {
      axios
        .post(
          this.apiHost + "/api/check_token",
          {},
          {
            headers: {
              Authorization: "Bearer " + this.token,
              uuid: this.uuid,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((response) => this.checkTokenResponse(response))
        .catch(function (error) {
          console.error("checkToken", error);
        });
    },
    async checkTokenResponse(response) {
      if (response.data.result === true) {
        await this.$store.commit("setIsLogin", true);
        await this.$store.commit("setAxiosHeaders", {
          key: "Authorization",
          val: "Bearer " + this.token,
        });
        await this.$store.commit("setAxiosHeaders", {
          key: "uuid",
          val: this.uuid,
        });
        await this.$store.commit("setAxiosHeaders", {
          key: "Accept",
          val: "application/json",
        });
        await this.$store.commit("setAxiosHeaders", {
          key: "Content-Type",
          val: "application/json",
        });
        this.getUserInfo();
      } else {
        this.cookies.remove(this.appName + "-token", null);
      }
    },
    getUserInfo() {
      axios
        .get(this.apiHost + "/api/user", {
          headers: this.$store.state.axiosHeaders,
        })
        .then((response) => this.setUserInfo(response))
        .catch(function (error) {
          console.error("checkToken", error);
        });
    },
    async setUserInfo(response) {
      if (response.data.result) {
        await this.$store.commit("setUserInfo", response.data.content[0]);
      }
      this.$store.commit("setIsLogin", true);
    },
  },
};
</script>

<style>
body {
  overflow-x: hidden;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}


@media only screen and (max-width: 1440px) {}

@media only screen and (max-width: 1366px) {}

@media only screen and (max-width: 1280px) {}

@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 992px) {}
</style>
