<template>
  <div :class="envMode == 'devel' ? 'block-show' : 'hide'" class="app-block">
    === Env Info === <br />
    Env : {{ envMode }}<br />
    ApiUrl : {{ apiUrl }} <br />
  </div>
</template>

<script>
import { useCookies } from "vue3-cookies";

export default {
  name: "VueEnv",
  modules: {},
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      envMode: "devel",
      url: "",
    };
  },
  created() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    window.innerWidth > 992
      ? (this.deviceType = "pc")
      : (this.deviceType = "mobile");
    this.$store.commit("setDeviceType", this.deviceType);
    const apiUrl =
      document.cookie
        .match("(^|;)\\s*" + this.appName + "-apiUrl\\s*=\\s*([^;]+)")
        ?.pop() || "";
    if (apiUrl === "")
      this.cookies.set(this.appName + "-apiUrl", encodeURI(this.apiHost));
    this.apiUrl = this.apiHost;

    if (this.appEnv !== undefined) {
      this.appEnv === "devel";
    }
    this.envMode = this.appEnv;
    this.$store.commit("setEnvMode", this.envMode);
    this.$watch(
      () => this.$route.path,
      () => {
        if (
          (this.$route.path.match("/movie/\\d+") &&
            this.$route.path.match("/movie/\\d+").length > 0) ||
          this.$route.path.match("/movie")
        ) {
          this.$store.commit("setSideBarShow", false);
        } else {
          this.$store.commit("setSideBarShow", true);
        }

        switch (this.$route.path) {
          case this.$route.path !== "/":
            this.cookies.set(
              this.appName + "-preRoute",
              encodeURI(this.$route.path)
            );
            this.$store.commit("preRoute", encodeURI(this.$route.path));
        }
      }
    );

    this.$store.commit("setAxiosHeaders", {
      key: "reqHost",
      val: location.protocol + "//" + location.host,
    });

    switch (this.$route.path) {
      case this.$route.path !== "/":
        this.cookies.set(
          this.appName + "-preRoute",
          encodeURI(this.$route.path)
        );
        this.$store.commit("preRoute", encodeURI(this.$route.path));
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      window.innerWidth > 992
        ? (this.deviceType = "pc")
        : (this.deviceType = "mobile");
      this.$store.commit("setDeviceType", this.deviceType);
    },
  },
};
</script>
<style>
.app-block {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
  font-size: 10pt;
  color: #00ff00;
  background-color: #000;
}
</style>
